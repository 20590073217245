import { useEffect, useRef } from "react";

const useRefreshInterval = (callback, dependencies = [], delay = 10000, executeOnMount = true) => {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            callbackRef.current();
        }

        if (executeOnMount) {
            tick(); // call function straight away, so no delay before first calls
        }

        const intervalId = setInterval(tick, delay);

        return () => clearInterval(intervalId);
    }, [delay, executeOnMount]);
};

export default useRefreshInterval;
