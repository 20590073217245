import { useContext } from "react";
import LocalisationContext from "Localisation/context";
import {
    Container,
    LeftCol,
    Logo,
    LogoLink,
    Copyright,
    SocialContainer,
    SocialTitle,
    TwitterLink,
    FacebookLink,
    LinkedInLink,
    Hidden,
} from "./styles/footer";

const Footer = () => {
    const t = useContext(LocalisationContext);
    return (
        <Container>
            <LeftCol>
                <LogoLink href="https://gnatta.com/" target="_blank" rel="noreferrer">
                    <Logo />
                </LogoLink>
                <Copyright>
                    &copy; {new Date().getFullYear()} Gnatta. {t.get("footer_rights_reserved")}
                </Copyright>
            </LeftCol>
            <SocialContainer>
                <SocialTitle>{t.get("footer_follow_us")}:</SocialTitle>
                <TwitterLink href="https://twitter.com/gnatta" target="_blank" rel="noreferrer">
                    <Hidden>{t.get("footer_twitter")}</Hidden>
                </TwitterLink>
                <FacebookLink href="https://www.facebook.com/GnattaOfficial" target="_blank" rel="noreferrer">
                    <Hidden>{t.get("footer_facebook")}</Hidden>
                </FacebookLink>
                <LinkedInLink href="https://www.linkedin.com/company/gnatta" target="_blank" rel="noreferrer">
                    <Hidden>{t.get("footer_linkedin")}</Hidden>
                </LinkedInLink>
            </SocialContainer>
        </Container>
    );
};

export default Footer;
