import { styled } from "@mui/material/styles";

export const HeaderContainer = styled("header")({
    backgroundColor: "#fff",
    padding: "2vh 5vw",
    "@media (min-width: 900px)": {
        paddingRight: "30vw",
    },
});

export const LogoLink = styled("a")({
    display: "block",
    height: "5vh",
    minHeight: "40px",
    "@media (min-width: 768px)": {
        height: "7vh",
    },
});

export const Logo = styled("img")({
    height: "100%",
});

export const HeaderTitle = styled("h1")({
    fontFamily: "'museo-slab', serif",
    fontWeight: 900,
    fontSize: "2.5rem",
    marginTop: "1rem",
    marginBottom: "0px",
    "@media (min-width: 1200px)": {
        fontSize: "3.125rem",
    },
    "@media (min-width: 1600px)": {
        fontSize: "4.375rem",
    },
});

export const SubTitle = styled("h3")({
    fontFamily: "'museo-sans', sans-serif",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "40px",
});
