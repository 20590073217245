import { styled } from "@mui/material/styles";
import Header from "Components/Header";
import SidePanel from "Components/SidePanel";
import Footer from "Components/Footer";
import Form from "./Form";

const SignUpPage = styled("div")({
    display: "flex",
    flex: "1",
    "@media (min-width: 900px)": {
        minHeight: "60vh",
    },
});

const PageContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
});

const Page = ({ settings }) => {
    return (
        <PageContainer>
            <Header />
            <SignUpPage>
                <Form domain={settings.siteDomain} />
                <SidePanel />
            </SignUpPage>
            <Footer />
        </PageContainer>
    );
};

export default Page;
