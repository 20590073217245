import { useContext } from "react";
import LocalisationContext from "Localisation/context";
import gnatta_icon from "Images/gnatta_icon.svg";
import { Container, Content, Image, Header, List, ListItem } from "./styles/sidepanel";

const SidePanel = () => {
    const t = useContext(LocalisationContext);
    return (
        <Container>
            <Content>
                <Image src={gnatta_icon} alt="Gnatta monitor" />
                <Header>{t.get("sidepanel_title")}</Header>
                <List>
                    <ListItem>{t.get("sidepanel_point_nocard")}</ListItem>
                    <ListItem>{t.get("sidepanel_point_controlsetup")}</ListItem>
                    <ListItem>{t.get("sidepanel_point_channels")}</ListItem>
                    <ListItem>{t.get("sidepanel_point_support")}</ListItem>
                    <ListItem>{t.get("sidepanel_point_30days")}</ListItem>
                </List>
            </Content>
        </Container>
    );
};

export default SidePanel;
