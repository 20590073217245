import { styled } from "@mui/material/styles";
import { ReactComponent as GnattaLogo } from "Images/logo_white.svg";
import gnatta_icon_twitter from "Images/gnatta_icon_twitter.svg";
import gnatta_icon_facebook from "Images/gnatta_icon_facebook.svg";
import gnatta_icon_linkedin from "Images/gnatta_icon_linkedin.svg";

export const Container = styled("footer")({
    backgroundColor: "#753090",
    padding: "15px 5vw",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (min-width: 768px)": {
        flexDirection: "row",
        padding: "50px 5vw",
    },
});

export const LeftCol = styled("div")({
    flex: "1",
});

export const Logo = styled(GnattaLogo)({
    width: "150px",
    "@media (min-width: 768px)": {
        width: "190px",
    },
});

export const LogoLink = styled("a")({
    display: "block",
    marginBottom: "15px",
    textAlign: "center",
    "@media (min-width: 768px)": {
        marginBottom: "35px",
        textAlign: "left",
    },
});

export const Copyright = styled("span")({
    display: "block",
    marginBottom: "15px",
    fontSize: "0.75rem",
    "@media (min-width: 768px)": {
        fontSize: "1rem",
        marginBottom: "0",
    },
});

export const SocialContainer = styled("div")({
    display: "flex",
    alignItems: "center",
});

export const SocialTitle = styled("h4")({
    fontFamily: "'museo-slab',sans-serif",
    fontWeight: "900",
    margin: 0,
    fontSize: "1rem",
    "@media (min-width: 768px)": {
        marginBottom: "0",
        fontSize: "1.25rem",
    },
});

export const Hidden = styled("span")({
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: "0",
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderWidth: "0",
});

const socialLink = {
    width: "35px",
    height: "35px",
    marginLeft: "10px",
    "&:hover": {
        opacity: "60%",
    },
    "@media (min-width: 768px)": {
        width: "40px",
        height: "40px",
        marginLeft: "15px",
    },
};

export const TwitterLink = styled("a")({
    ...socialLink,
    backgroundImage: `url(${gnatta_icon_twitter})`,
});

export const FacebookLink = styled("a")({
    ...socialLink,
    backgroundImage: `url(${gnatta_icon_facebook})`,
});

export const LinkedInLink = styled("a")({
    ...socialLink,
    backgroundImage: `url(${gnatta_icon_linkedin})`,
});
