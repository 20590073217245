import ENDPOINTS from "./endpoints";

const register = (form) => fetch(ENDPOINTS.register, { 
        method: "POST", 
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
    });

export default register;
