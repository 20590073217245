import React, {useContext} from "react";
import { BodyCopy } from "Components/page";
import LocalisationContext from "Localisation/context";
import { useNavigate } from "react-router-dom";
import {CompletionContainer, CompletionDescription, EmailSentIcon, Header} from "../styles/form";

export const Completion = ({form}) => {
    const t = useContext(LocalisationContext);
    const nav = useNavigate();

    React.useEffect(() => {
        // If the user navigates directly to this URL without completing page 2, redirect them back
        if (!form || !form.TermsAgreed){
            nav("/step-1", {replace: true})
        }
    });

    return (
    <CompletionContainer>
        <EmailSentIcon />
        <CompletionDescription>
            <Header>{t.get("form_title_checkemail")}</Header>
            <BodyCopy>{t.get("form_checkemail_verification")}</BodyCopy>
            <BodyCopy>{t.get("form_license_default_setup")}</BodyCopy>
        </CompletionDescription>
    </CompletionContainer>
)};
