import { useContext } from "react";
import LocalisationContext from "Localisation/context";
import logo from "Images/logo.svg";
import { HeaderContainer, LogoLink, Logo, HeaderTitle, SubTitle } from "./styles/header";

const Header = () => {
    const localisation = useContext(LocalisationContext);
    return (
        <HeaderContainer>
            <LogoLink href="https://gnatta.com/" target="_blank" rel="noreferrer">
                <Logo src={logo} alt="Gnatta" />
            </LogoLink>
            <HeaderTitle>
                {localisation.get("header_title_part1")}
                <br />
                {localisation.get("header_title_part2")}
            </HeaderTitle>
            <SubTitle>{localisation.get("header_sub_title")}</SubTitle>
        </HeaderContainer>
    );
};

export default Header;
