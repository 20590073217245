import { useContext, useState } from "react";
import { useSearchParams, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import register from "Api/register";
import LocalisationContext from "Localisation/context";
import ReCaptchaContext from "ReCaptcha/context";
import { BodyCopy } from "Components/page";
import {
    FormContainer,
    Header,
    Main,
} from "./styles/form";
import AccountDetails from "./AccountDetails";
import TierSelect from "./TierSelect";
import {Completion} from "./Completion";
import { TIERS } from "../constants";

const Form = ({ domain }) => {
    const t = useContext(LocalisationContext);
    const reCaptcha = useContext(ReCaptchaContext);
    const [searchParams] = useSearchParams();
    const tier = searchParams.get("tier")?.toLowerCase() ?? "";
    const nav = useNavigate();
    // If the tier is valid then pre-select it, otherwise default to Premium.
    const preSelectedTier = Object.keys(TIERS).includes(tier) ? tier : TIERS.premium;

    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formError, setFormError] = useState(false);
    const [page, setPage] = useState(1);
    const [form, setForm] = useState({
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        Country: null,
        CompanyName: "",
        CompanyDomain: "",
        EmailAddress: "",
        Tier: preSelectedTier,
        TermsAgreed: false,
    });

    const [errors, setErrors] = useState({});

    const submitForm = (ev) => {
        ev.preventDefault();

        if (page === 1) {
            setPage(2);
            nav("/step-2");
            return;
        }

        if (!form.TermsAgreed) {
            setErrors({ TermsAgreed: ["error_required"] });
            return;
        }

        setFormSubmitting(true);

        (async () => {
            try {
                const requestToken = await reCaptcha.getToken("signup");
                const response = await register({ ...form, requestToken });

                if (!response.ok) {
                    const err = await response.json();
                    console.error(err);
                    setErrors(err.errors);
                    setPage(1);
                    setFormSubmitting(false);
                    nav("/step-1");
                    return;
                }

                setPage(3);
                nav("/success");
            } catch (e) {
                setFormError(true);
            }
        })();
    };

    const hasError = (key) => !!errors[key];
    const getError = (key) => (!!errors[key] ? errors[key].map((err) => t.get(`form_${err}`)).join("\r\n") : null);

    if (formError) {
        return (
            <Main>
                <Header>{t.get("form_title_error")}</Header>
                <BodyCopy>{t.get("form_error_generic")}</BodyCopy>
            </Main>
        );
    }

    return (
        <Main>
            <FormContainer onSubmit={submitForm}>
                <Routes>
                <Route path={`step-1`} element={<AccountDetails domain={domain} form={form} setForm={setForm} getError={getError} hasError={hasError} />} />
                <Route path={`step-2`} element={<TierSelect setForm={setForm} form={form} formSubmitting={formSubmitting} setPage={setPage} />} />
                <Route path={"success"} element={<Completion form={form} />} />
                <Route path="*" element={<Navigate to="step-1" />} />
                </Routes>
            </FormContainer>
        </Main>
    );
};

export default Form;
