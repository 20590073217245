import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

export const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
});

export const Logo = styled("img")({
    width: "196px",
    display: "block",
    marginBottom: "50px",
});

export const Spinner = styled(CircularProgress)({
    color: "#9746B7",
});
