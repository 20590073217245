import React, {useContext} from "react";
import FormGroup from "@mui/material/FormGroup";
import { useNavigate } from "react-router-dom";
import { Spinner } from "Components/Loading/styles/loading";
import LocalisationContext from "Localisation/context";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Header,
    MostPopular, OutlinedButton,
    PriceLabel,
    PriceLabelDetail, StartTrialButtonContainer,
    HyperlinkContainer,
    TierBox,
    TierContainer
} from "../styles/form";
import { TIERS } from "../../constants";

export const TierSelect = ({setForm, form, formSubmitting, setPage}) => {
    const t = useContext(LocalisationContext);
    const nav = useNavigate();

    React.useEffect(() => {
        // If the user navigates directly to this URL without completing page 1, redirect them back
        if (!form || form.FirstName === ""){
            nav("/step-1", {replace: true})
        }
    });

    const getLocalCurrencyFormat = (value) => {
        const userLang = navigator.language;
        return new Intl.NumberFormat(userLang, { style: "currency", currency: "GBP", maximumSignificantDigits: 2 }).format(value)
    };

    return (
    <div>
    <Header>{t.get("form_title_selectTier")}</Header>
    <TierContainer>
        <TierBox
            selected={form.Tier === TIERS.starter}
            onClick={() => setForm({ ...form, Tier: TIERS.starter })}
            type="button"
        >
            {t.get("form_label_tier_starter")}
            <br/>
            <PriceLabel>
                <div>{getLocalCurrencyFormat(0)}<PriceLabelDetail>/{t.get("form_label_detail_month")}</PriceLabelDetail></div>
                <PriceLabelDetail>{t.get("form_label_detail_per_user")}</PriceLabelDetail>
                <PriceLabelDetail>({t.get("form_label_detail_excluding_vat")})</PriceLabelDetail>
            </PriceLabel>
        </TierBox>
        <TierBox
            selected={form.Tier === TIERS.lite}
            onClick={() => setForm({ ...form, Tier: TIERS.lite })}
            type="button"
        >
            {t.get("form_label_tier_lite")}
            <br/>
            <PriceLabel>
                <div>{getLocalCurrencyFormat(30)}<PriceLabelDetail>/{t.get("form_label_detail_month")}</PriceLabelDetail></div>
                <PriceLabelDetail>{t.get("form_label_detail_per_user")}</PriceLabelDetail>
                <PriceLabelDetail>({t.get("form_label_detail_excluding_vat")})</PriceLabelDetail>
            </PriceLabel>
        </TierBox>
        <TierBox
            selected={form.Tier === TIERS.premium}
            onClick={() => setForm({ ...form, Tier: TIERS.premium })}
            type="button"
        >
            <MostPopular />
            {t.get("form_label_tier_premium")}
            <br/>
            <PriceLabel>
                <div>{getLocalCurrencyFormat(55)}<PriceLabelDetail>/{t.get("form_label_detail_month")}</PriceLabelDetail></div>
                <PriceLabelDetail>{t.get("form_label_detail_per_user")}</PriceLabelDetail>
                <PriceLabelDetail>({t.get("form_label_detail_excluding_vat")})</PriceLabelDetail>
            </PriceLabel>
        </TierBox>
        <TierBox
            selected={form.Tier === TIERS.professional}
            onClick={() => setForm({ ...form, Tier: TIERS.professional })}
            type="button"
        >
            {t.get("form_label_tier_professional")}
            <br/>
            <PriceLabel>
                <div>{getLocalCurrencyFormat(80)}<PriceLabelDetail>/{t.get("form_label_detail_month")}</PriceLabelDetail></div>
                <PriceLabelDetail>{t.get("form_label_detail_per_user")}</PriceLabelDetail>
                <PriceLabelDetail>({t.get("form_label_detail_excluding_vat")})</PriceLabelDetail>
            </PriceLabel>
        </TierBox>
        <TierBox
            selected={form.Tier === TIERS.enterprise}
            onClick={() => setForm({ ...form, Tier: TIERS.enterprise })}
            type="button"
        >
            {t.get("form_label_tier_enterprise")}
            <br/>
            <PriceLabel>
                <div>{getLocalCurrencyFormat(130)}<PriceLabelDetail>/{t.get("form_label_detail_month")}</PriceLabelDetail></div>
                <PriceLabelDetail>{t.get("form_label_detail_per_user")}</PriceLabelDetail>
                <PriceLabelDetail>({t.get("form_label_detail_excluding_vat")})</PriceLabelDetail>
            </PriceLabel>
        </TierBox>
    </TierContainer>
    <HyperlinkContainer>
        <a href="https://gnatta.com/terms/" target="_blank" rel="noopener noreferrer">
            {t.get("form_terms_termsandconditions")}
        </a>
    </HyperlinkContainer>
    <FormGroup>
        <FormControlLabel
            control={
                <Checkbox onChange={(ev) => setForm({ ...form, TermsAgreed: ev.target.checked })} />
            }
            label={t.get("form_label_agreetheterms")}
            checked={form.TermsAgreed}
        />
    </FormGroup>

    <StartTrialButtonContainer>
        <OutlinedButton
            variant="outlined"
            onClick={() => {
                setPage(1);
                nav("/step-1")
            }}>
            {t.get("form_back")}
        </OutlinedButton>
        <Button variant="contained" type="submit" disabled={formSubmitting || !form.TermsAgreed}>
            {formSubmitting ? <Spinner /> : t.get("form_submit")}
        </Button>
    </StartTrialButtonContainer>
</div>)};

export default TierSelect;
