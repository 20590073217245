import { styled } from "@mui/material/styles";
import tick_bullet_point from "Images/tick_bullet_point_white.svg";

export const Container = styled("aside")({
    display: "none",
    boxSizing: "border-box",
    width: "20vw",
    maxWidth: "450px",
    right: "5vw",
    position: "absolute",
    top: "10vh",
    "&:before": {
        position: "absolute",
        content: '""',
        top: "-20px",
        left: "20px",
        right: "-20px",
        bottom: "20px",
        border: "6px dotted #333953",
    },
    "@media (min-width: 900px)": {
        display: "block",
    },
});

export const Content = styled("div")({
    position: "relative",
    backgroundColor: "#9746B7",
    color: "#ffffff",
    padding: "20px",
    boxSizing: "border-box",
    "@media (min-width: 1600px)": {
        padding: "40px",
    },
});

export const Image = styled("img")({
    display: "block",
    margin: "0 auto",
    width: "100%",
    maxWidth: "300px",
    "@media (min-width: 1600px)": {
        margin: "10px auto",
    },
});

export const Header = styled("h2")({
    textAlign: "center",
    fontFamily: "museo-slab, Open Sans",
    fontWeight: "900",
    fontSize: "1.875rem",
});

export const List = styled("ul")({
    margin: "0",
    padding: "0",
    listStyle: "none",
    fontFamily: "museo-sans, sans-serif",
    fontWeight: "500",
});

export const ListItem = styled("li")({
    marginBottom: "20px",
    background: `left center no-repeat url('${tick_bullet_point}')`,
    padding: "6px 0 0px 40px",
    minHeight: "30px",
    boxSizing: "border-box",
});
