import { useEffect } from "react";
import ReCaptchaContext from "./context";

const ReCaptcha = ({ siteKey, children }) => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [siteKey]);

    const getToken = (action) => {        
        return window.grecaptcha.execute(siteKey, { action });
    };

    const context = { getToken };

    return (
        <ReCaptchaContext.Provider value={context}>
            {children}
        </ReCaptchaContext.Provider>
    )
};

export default ReCaptcha;
