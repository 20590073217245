import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import complete from "Api/complete";
import getSetupStatus from "Api/setupStatus";
import LocalisationContext from "Localisation/context";
import logoSvg from "Images/logo.svg";
import loadingSvg from "Images/completing.svg";
import useRefreshInterval from "Utils/Hooks/useRefreshInterval"
import { set, get, removeItem } from "Utils/LocalStorage/localStorage";
import "./index.css";
import SETUP_STATUS from "./CompanySetupStatuses";

const GnattaSignupDetails = "GNATTA_SIGNUP_DETAILS";

const CompletePage = () => {
    const t = useContext(LocalisationContext);

    // Params
    const [params] = useSearchParams();
    const request = params.get("request");
    const token = params.get("token");

    // State
    const [error, setError] = useState("");
    const [companyId, setCompanyId] = useState(null);
    const [setupStatus, setSetupStatus] = useState(null);
    const [invitationLink, setInvitationLink] = useState("");
    const [shouldRunInitialRequest,setShouldRunInitialRequest] = useState(false);

    useEffect(() => {
        const localStorageValue = get(GnattaSignupDetails);
        if(localStorageValue){
            setCompanyId(localStorageValue.companyId);
            setInvitationLink(localStorageValue.invitationLink);
        } else {
            setShouldRunInitialRequest(true);
        }
    }, []);

    useEffect(() => {
        if(!shouldRunInitialRequest){
            return;
        }

        if (!request || !token) {
            setError("invalid_parameters");
            return;
        }

        (async () => {
            try {
                const result = await complete(request, token);
                const data = await result.json();

                if (!data.success) {
                    setError(`${data.message || "invalid_parameters"}`);
                    return;
                }

                // When the Company ID is null, the registration has already been consumed - redirect and exit early in this case.
                if(data.companyId === null){
                    document.location = data.invitationLink;
                    return;
                }

                setCompanyId(data.companyId);
                setInvitationLink(data.invitationLink);
                set(GnattaSignupDetails, { companyId: data.companyId, invitationLink: data.invitationLink })
            }
            catch {
                setError("generic");
            }
        })();
    }, [request, shouldRunInitialRequest, token]);


    const handleSetupStatus = async () => {
        if(companyId === null) return;
        if(error !== "") return;

        try {
            const response = await getSetupStatus(companyId);
            const data = await response.json();
            setSetupStatus(data.status);

            if(data.status === SETUP_STATUS.failed) {
                removeItem(GnattaSignupDetails);
                setError(data.error.message);
            }
            if(data.status === SETUP_STATUS.completed) {
                removeItem(GnattaSignupDetails);
                document.location = invitationLink;
            }
        }
        catch {
            removeItem(GnattaSignupDetails);
            setError("contact_gnatta");
        }
    };
    useRefreshInterval(async () => await handleSetupStatus(), [companyId, setupStatus], 1000, false);

    return (
        <div className="signup-complete">
            <div className="signup-complete-center">
                <img src={logoSvg} alt="Gnatta" />
                <br />
                <img className="complete-loading-icon" src={loadingSvg} alt="Processing" />
                {error && (
                    <div>
                        <h2>{t.get("complete_error_header")}</h2>
                        <p>{t.get(`complete_error_${error}`)}</p>
                    </div>
                )}
                {!error && (
                    <div>
                        <h2>{t.get("complete_waiting_header")}</h2>
                        <p>{t.get("complete_waiting_message")}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompletePage;
