import { useEffect } from "react";
import LocalisationContext from "./context";

const Localisation = ({ translations, children }) => {
    const get = (key) => translations[key] || key;
    const context = { get };

    useEffect(() => {
        document.title = get("page_title");
    // eslint-disable-next-line
    }, [translations]);

    return (
        <LocalisationContext.Provider value={context}>
            {children}
        </LocalisationContext.Provider>
    );
};

export default Localisation;
