import React from "react";
import logo from "Images/logo.svg";
import { Container, Logo, Spinner } from "./styles/loading";

const Loading = () => {
    return (
        <Container>
            <Logo src={logo} alt="Gnatta" />
            <Spinner />
        </Container>
    );
};

export default Loading;
