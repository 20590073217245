import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import initialise from "Api/initialise";
import ReCaptcha from "ReCaptcha";
import Localisation from "Localisation";
import SignupPage from "SignupPage";
import CompletePage from "CompletePage";
import Loading from "Components/Loading";
import * as GoogleTags from "./GoogleTags";

GoogleTags.init();

const App = () => {
    const [loading, setLoading] = useState(true);
    const [localisation, setLocalisation] = useState({});
    const [settings, setSettings] = useState();

    useEffect(() => {
        (async () => {
            const init = await initialise();
            setLocalisation(init.localisation);
            setSettings(init.settings);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <ReCaptcha siteKey={settings.reCaptchaSiteKey}>
            <Localisation translations={localisation}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<SignupPage settings={settings} />} />
                        <Route path="/complete" element={<CompletePage />} />
                        <Route path="*" element={<Navigate to="/step-1" />} />
                    </Routes>
                </BrowserRouter>
            </Localisation>
        </ReCaptcha>
    );
};

export default App;
