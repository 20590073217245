import React, { useContext, useMemo } from "react";
import LocalisationContext from "Localisation/context";
import FormGroup from "@mui/material/FormGroup";
import { getCountryNames } from "Utils/ISOCountries/countries";
import {
    Button,
    CompanyDomainContainer,
    CompanyDomainLabel,
    CompanyDomainPreview,
    Header,
    NextButtonContainer,
    TextField,
    TextFieldBase,
    Autocomplete,
    HyperlinkContainer
} from "../styles/form";

const DOMAIN_LENGTH_LIMIT = 16;

const getCountryOptions = () => {
    const options = [];
    for (const [key, value] of Object.entries(getCountryNames())) {
        options.push({ label: value, id: key });
    }
    return options;
}

export const AccountDetails = ({ form, hasError, getError, setForm, domain }) => {
    const t = useContext(LocalisationContext);
    const countryOptions = useMemo(() => getCountryOptions(), []);
    const [countryValue, setCountryValue] = React.useState(() => {
        return form.Country ? countryOptions.find(o => o.id === form.Country) : null
    });

    return (
        <div>
            <Header>{t.get("form_title_accountDetails")}</Header>
            <FormGroup row>
                <TextField
                    label={t.get("form_label_FirstName")}
                    value={form.FirstName}
                    onChange={(ev) => {
                        setForm({ ...form, FirstName: ev.target.value });
                    }}
                    error={hasError("FirstName")}
                    helperText={getError("FirstName")}
                    variant="standard"
                    margin="normal"
                    required
                    inputProps={{
                        maxLength: 64,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label={t.get("form_label_LastName")}
                    value={form.LastName}
                    onChange={(ev) => {
                        setForm({ ...form, LastName: ev.target.value });
                    }}
                    error={hasError("LastName")}
                    helperText={getError("LastName")}
                    variant="standard"
                    margin="normal"
                    required
                    inputProps={{
                        maxLength: 64,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </FormGroup>

            <FormGroup row>
                <TextField
                    label={t.get("form_label_PhoneNumber")}
                    value={form.PhoneNumber}
                    onChange={(ev) => {
                        setForm({ ...form, PhoneNumber: ev.target.value });
                    }}
                    error={hasError("PhoneNumber")}
                    helperText={getError("PhoneNumber")}
                    variant="standard"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <Autocomplete
                    id="country-select"
                    value={countryValue}
                    onChange={(_, newValue) => {
                        setCountryValue(newValue);
                        setForm({ ...form, Country: newValue ? newValue.id : null })
                    }}
                    options={countryOptions}
                    renderInput={(params) =>
                        <TextFieldBase {...params}
                            variant="standard"
                            label={t.get("form_label_Country")}
                            required
                            error={hasError("Country")}
                            helperText={getError("Country")}
                            InputLabelProps={{ shrink: true }}
                        />}
                />
            </FormGroup>
            <FormGroup row>
                <TextField
                    label={t.get("form_label_CompanyName")}
                    value={form.CompanyName}
                    onChange={(ev) => {
                        setForm({ ...form, CompanyName: ev.target.value });
                    }}
                    error={hasError("CompanyName")}
                    helperText={getError("CompanyName")}
                    variant="standard"
                    margin="normal"
                    required
                    inputProps={{
                        maxLength: 64,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label={t.get("form_label_EmailAddress")}
                    value={form.EmailAddress}
                    onChange={(ev) => {
                        setForm({ ...form, EmailAddress: ev.target.value });
                    }}
                    error={hasError("EmailAddress")}
                    helperText={getError("EmailAddress")}
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    inputProps={{
                        maxLength: 256,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </FormGroup>

            <FormGroup row>
                <TextField
                    label={t.get("form_label_CompanyDomain")}
                    value={form.CompanyDomain}
                    onChange={(ev) => {
                        if (ev.target.value.length > DOMAIN_LENGTH_LIMIT) {
                            return;
                        }

                        setForm({ ...form, CompanyDomain: ev.target.value });
                    }}
                    error={hasError("CompanyDomain")}
                    helperText={getError("CompanyDomain")}
                    variant="standard"
                    margin="normal"
                    required
                    inputProps={{
                        maxLength: 16,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
                <CompanyDomainContainer>
                    <CompanyDomainLabel>{t.get("form_label_companyDomain_explanation")}</CompanyDomainLabel>
                    <CompanyDomainPreview>
                        {form.CompanyDomain || t.get("form_companyDomain_previewDefault")}
                        {domain}
                    </CompanyDomainPreview>
                </CompanyDomainContainer>
            </FormGroup>

            <NextButtonContainer>
                <Button variant="contained" type="submit">
                    {t.get("form_nextpage")}
                </Button>
            </NextButtonContainer>
            <HyperlinkContainer>
                <span>{t.get("form_link_to_our")} </span>
                <a href="https://gnatta.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                    {t.get("form_privacy_policy")}
                </a>
            </HyperlinkContainer>
        </div>
    )
};

export default AccountDetails;
