import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiButton from "@mui/material/Button";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox from "@mui/material/Checkbox";
import { ReactComponent as MostPopularImage } from "Images/most_popular.svg";
import { ReactComponent as EmailSent } from "Images/email_sent.svg";

export const Main = styled("main")({
    flex: 2,
    padding: "2vh 5vw",
});

export const Header = styled("h3")({
    fontFamily: "'museo-slab', sans-serif",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "31px",
    marginBottom: "10px",
})

export const TextFieldBase = styled(MuiTextField)({
    "& .MuiInput-root": {
        fontFamily: "'museo-sans'",
        "&:after": {
            borderBottom: "2px solid #9746b7",
        },
        "&.MuiInput-underline": {
            ":hover": {
                ":before": {
                    borderBottom: "2px solid #9746b7",
                },
            },
        },
    },
    "& .MuiInputLabel-root": {
        fontFamily: "'museo-sans'",
        "&.Mui-focused": {
            color: "#9746b7",
        }
    },
});

export const TextField = styled(TextFieldBase)({
    margin: "10px 15px 10px 0px",
    flex: "1 300px",
});

export const Autocomplete = styled(MuiAutocomplete)({
    margin: "10px 15px 10px 0px",
    flex: "1 300px",

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9746b7 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.42)",
    },
});

export const CompanyDomainContainer = styled("div")({
    margin: "10px 15px 10px 00px",
    flex: "1 300px",
    fontFamily: "'museo-sans'",
});

export const CompanyDomainLabel = styled("div")({
    color: "#B2B6C2",
    fontSize: "14px",
    lineHeight: "17px",
})

export const CompanyDomainPreview = styled("div")({
    color: "#9746B7",
    fontSize: "22px",
    lineHeight: "40px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
});

export const Button = styled(MuiButton)({
    backgroundColor: "#3CAF88",
    color: "white",
    boxShadow: "none",
    fontWeight: "900",
    width: "200px",
    padding: "15px",
    fontFamily: "'museo-slab'",
    fontSize: "20px",
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "6px",
    textAlign: "center",
    ":hover": {
        background: "#1c926a",
        borderColor: "#1c926a",
        transform: "scale(1.125)",
        boxShadow: "none",
    },
});

export const OutlinedButton = styled(MuiButton)({
    border: "2px solid #3CAF88",
    borderRadius: "6px",
    color: "#3CAF88",
    width: "200px",
    fontWeight: "900",
    padding: "15px",
    fontFamily: "'museo-slab'",
    fontSize: "20px",
    textTransform: "none",
    lineHeight: "24px",
    ":hover": {
        opacity: 0.6,
        backgroundColor: "transparent",
        border: "2px solid #3CAF88",
    },
});

export const NextButtonContainer = styled("div")({
    margin: "25px 0px",
});

export const FormContainer = styled("form")({
    "@media (min-width: 900px)": {
        marginRight: "25vw",
    },
});

export const HyperlinkContainer = styled("div")({
    fontFamily: "'museo-sans'",
    marginTop: "30px",
});

export const FormControlLabel = styled(MuiFormControlLabel)({
    "& .MuiFormControlLabel-label": {
        fontFamily: "'museo-sans'",
    },
});

export const Checkbox = styled(MuiCheckbox)({
    "&.Mui-checked": {
        color: "#9746B7",
    },
});

export const TierContainer = styled("div")({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "20px",
    marginTop: "30px",
    justifyContent: "center",
});

export const PriceLabel = styled("span")({
    color: "#333953",
    fontSize: "2.5rem",
    display: "flex",
    flexDirection: "column",
});

export const PriceLabelDetail = styled("span")({
    fontWeight: "500",
    fontSize: "1rem",
    fontFamily: "museo-sans",
});

export const TierBox = styled("button", {
    shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
    position: "relative",
    fontFamily: "'museo-slab'",
    fontSize: "22px",
    border: "2px solid #E0E2E7",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    color: "#9746B7",
    textAlign: "center",
    fontWeight: "900",
    minWidth: "184px",
    flex: "1",
    height: "150px",
    ":hover > span": {
        color: "#FFFFFF",
    },
    ":hover": {
        backgroundColor: "#9746B7",
        color: "#FFFFFF",
    },
    ...(selected && {
        backgroundColor: "#9746B7",
        color: "#FFFFFF",
        " > span": {
            color: "#FFFFFF",
        }
    }),
}));

export const MostPopular = styled(MostPopularImage)({
    position: "absolute",
    top: "-75px",
    right: "-20px",
});

export const StartTrialButtonContainer = styled("div")({
    margin: "25px 0px",
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
});

export const CompletionContainer = styled("div")({
    display: "flex",
    backgroundColor: "#fff",
    padding: "3rem",
    gap: "30px",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "26px",
});

export const EmailSentIcon = styled(EmailSent)({
    flex: "1 0",
    minWidth: "120px",
});

export const CompletionDescription = styled("div")({
    flex: "1 80%",
});
